<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-tabs color="deep-purple accent-4">
            <v-tab>Sent Request</v-tab>
            <v-tab>Recieved Request</v-tab>

            <v-tab-item class="mt-10">
              <v-tabs color="green">
                <v-tab>All</v-tab>
                <v-tab>Pending</v-tab>
                <v-tab>Approved</v-tab>
                <v-tab>Generate Transfer</v-tab>

                <v-tab-item>
                  <v-data-table
                    :headers="dessertHeaders"
                    :items="sent_all"
                    item-key="id"
                    class="elevation-0"
                    style="background: #f5f5f5"
                  >
                    <template v-slot:top>
                      <v-toolbar
                        elevation="2"
                        style="background: #f5f5f5; font-size: 26px"
                      >
                      </v-toolbar>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-btn
                        color="primary"
                        style="padding-left: 0px !important"
                        text
                        @click="Approve(item)"
                        >Details</v-btn
                      >
                    </template>

                    <template v-slot:item.dateaction="{ item }">
                      <p class="mb-0">
                        {{ new Date(item.date).toDateString() }}
                      </p>
                    </template>

                    <template v-slot:item.leng="{ item }">
                      <p class="mb-0">
                        {{ item.transaction_ids.length }}
                      </p>
                    </template>
                  </v-data-table>
                </v-tab-item>

                <v-tab-item>
                  <v-data-table
                    :headers="dessertHeaders"
                    :items="sent_pending"
                    item-key="id"
                    class="elevation-0"
                    style="background: #f5f5f5"
                  >
                    <template v-slot:top>
                      <v-toolbar
                        elevation="2"
                        style="background: #f5f5f5; font-size: 26px"
                      >
                        <v-spacer></v-spacer>
                      </v-toolbar>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-btn
                        color="primary"
                        style="padding-left: 0px !important"
                        text
                        @click="Approve(item)"
                        >Details</v-btn
                      >
                    </template>

                    <template v-slot:item.dateaction="{ item }">
                      <p class="mb-0">
                        {{ new Date(item.date).toDateString() }}
                      </p>
                    </template>

                    <template v-slot:item.leng="{ item }">
                      <p class="mb-0">
                        {{ item.transaction_ids.length }}
                      </p>
                    </template>
                  </v-data-table>
                </v-tab-item>

                <v-tab-item>
                  <v-data-table
                    :headers="dessertHeaders"
                    :items="sent_approved"
                    item-key="id"
                    class="elevation-0"
                    style="background: #f5f5f5"
                  >
                    <template v-slot:top>
                      <v-toolbar
                        elevation="2"
                        style="background: #f5f5f5; font-size: 26px"
                      >
                      </v-toolbar>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-btn
                        color="primary"
                        style="padding-left: 0px !important"
                        text
                        @click="Approve(item)"
                        >Details</v-btn
                      >
                    </template>

                    <template v-slot:item.dateaction="{ item }">
                      <p class="mb-0">
                        {{ new Date(item.date).toDateString() }}
                      </p>
                    </template>

                    <template v-slot:item.leng="{ item }">
                      <p class="mb-0">
                        {{ item.transaction_ids.length }}
                      </p>
                    </template>
                  </v-data-table>
                </v-tab-item>

                <v-tab-item>
                  <GenerateTransfer />
                </v-tab-item>

              </v-tabs>
            </v-tab-item>

            <v-tab-item class="mt-10">
              <v-tabs color="green">
                <v-tab>All</v-tab>
                <v-tab>Pending</v-tab>
                <v-tab>Approved</v-tab>

                <v-tab-item>
                  <v-data-table
                    :headers="dessertHeaders"
                    :items="received_all"
                    item-key="id"
                    class="elevation-0"
                    style="background: #f5f5f5"
                  >
                    <template v-slot:top>
                      <v-toolbar
                        elevation="2"
                        style="background: #f5f5f5; font-size: 26px"
                      >
                      </v-toolbar>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-btn
                        color="primary"
                        style="padding-left: 0px !important"
                        text
                        @click="GetApprove(item)"
                        >Details</v-btn
                      >
                    </template>

                    <template v-slot:item.dateaction="{ item }">
                      <p class="mb-0">
                        {{ new Date(item.date).toDateString() }}
                      </p>
                    </template>

                    <template v-slot:item.leng="{ item }">
                      <p class="mb-0">
                        {{ item.transaction_ids.length }}
                      </p>
                    </template>
                  </v-data-table>
                </v-tab-item>

                <v-tab-item>
                  <v-data-table
                    :headers="dessertHeaders"
                    :items="received_pending"
                    item-key="id"
                    class="elevation-0"
                    style="background: #f5f5f5"
                  >
                    <template v-slot:top>
                      <v-toolbar
                        elevation="2"
                        style="background: #f5f5f5; font-size: 26px"
                      >
                      </v-toolbar>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-btn
                        color="primary"
                        style="padding-left: 0px !important"
                        text
                        @click="GetApprove(item)"
                        >Details</v-btn
                      >
                    </template>

                    <template v-slot:item.dateaction="{ item }">
                      <p class="mb-0">
                        {{ new Date(item.date).toDateString() }}
                      </p>
                    </template>

                    <template v-slot:item.leng="{ item }">
                      <p class="mb-0">
                        {{ item.transaction_ids.length }}
                      </p>
                    </template>
                  </v-data-table>
                </v-tab-item>

                <v-tab-item>
                  <v-data-table
                    :headers="dessertHeaders"
                    :items="received_approved"
                    item-key="id"
                    class="elevation-0"
                    style="background: #f5f5f5"
                  >
                    <template v-slot:top>
                      <v-toolbar
                        elevation="2"
                        style="background: #f5f5f5; font-size: 26px"
                      >
                      </v-toolbar>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-btn
                        color="primary"
                        style="padding-left: 0px !important"
                        text
                        @click="GetApprove(item)"
                        >Details</v-btn
                      >
                    </template>

                    <template v-slot:item.dateaction="{ item }">
                      <p class="mb-0">
                        {{ new Date(item.date).toDateString() }}
                      </p>
                    </template>

                    <template v-slot:item.leng="{ item }">
                      <p class="mb-0">
                        {{ item.transaction_ids.length }}
                      </p>
                    </template>
                  </v-data-table>
                </v-tab-item>
              </v-tabs>
            </v-tab-item>

          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import GenerateTransfer from '../../../components/Transfer/GenarateTransfer'

export default {
  components: {
    GenerateTransfer,
  },
  data: () => ({
    dessertHeaders: [
      {
        text: "Request ID",
        align: "start",
        value: "id",
        sortable: false,
      },
      { text: "Date", value: "dateaction", sortable: false },
      { text: "Status", value: "is_approved", sortable: false },
      { text: "Items", value: "item_count", sortable: false },
      { text: "Name", value: "name", sortable: false },
      { text: "Location", value: "location", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
    orders: [],
    user: {},
    id: 0,
    flag: 0,
    sent_all: [],
    sent_pending: [],
    sent_approved: [],
    received_all: [],
    received_pending: [],
    received_approved: [],
  }),
  methods: {
    getALlRequest() {
      axios
        .get(`hub/get_all_setter_transfer_request/${this.id}/${this.flag}/`)
        .then((response) => {
          if (response.data.success) {
            this.sent_all = response.data.data;
          }
        });

      axios
        .get(
          `hub/get_all_setter_transfer_pending_request/${this.id}/${this.flag}/`
        )
        .then((response) => {
          if (response.data.success) {
            this.sent_pending = response.data.data;
          }
        });

      axios
        .get(`hub/get_all_setter_transfer_request/${this.id}/${this.flag}/`)
        .then((response) => {
          if (response.data.success) {
            this.sent_approved = response.data.data;
          }
        });

      // Received
      axios
        .get(`hub/get_all_transfer_request/${this.id}/${this.flag}/`)
        .then((response) => {
          if (response.data.success) {
            this.received_all = response.data.data;
          }
        });

      axios
        .get(`hub/get_all_transfer_pending_request/${this.id}/${this.flag}/`)
        .then((response) => {
          if (response.data.success) {
            this.received_pending = response.data.data;
          }
        });

      axios
        .get(`hub/get_all_transfer_approved_request/${this.id}/${this.flag}/`)
        .then((response) => {
          if (response.data.success) {
            this.received_approved = response.data.data
          }
        });
    },

    GetApprove(item) {
      this.$router.push({
        path: "/merchant-managerdashboard/pages/hub-transfer/approval/sent",
        query: { id: item.id },
      });
    },
    Approve(item) {
      this.$router.push({
        path: "/merchant-managerdashboard/pages/hub-transfer/approval",
        query: { id: item.id },
      });
    },
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.id = this.$route.query.id;
    this.flag = this.$route.query.flag;
    if (this.user.user_id && this.id && this.flag) {
      this.getALlRequest();
    }
  },
};
</script>


<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>




